import React from 'react';
import withStore from "@/hocs/withStore";
import Svg from "@/Pages/All/Svg";

const SelectCity = (props) => {
    const {stores = {}, city} = props;
    const {selectCityStore = {}} = stores;

    return <div className={`headerBox__location`} onClick={() => selectCityStore.openModal()}>
        <Svg id={`location`} className={`svg__location`} />
        {city}
    </div>
}

export default withStore(SelectCity)
